
/* 
Styles for Tiles
*/

.pricing, .restrictions, .forecast, .market-sell-rates, .market-summary, .pickup, .compset, .market, .flights, .pace, .hotel-summary, .budget{
    color: #333333;
    /* width: 90%; */
}
.otb-tile{
    width: 100%;
}


.tile-wrap .mergeable {
    cursor: move;
}

.tile-title-row {
    font-family: 'SFUI Medium';
    font-size: 12px ;
    font-weight: 500;
    text-align: left;
    padding-bottom: 0px;
}

.tile-main-number-row{
    padding-top: 6px;
    height: 16px;
}

.main-number {
    text-align: left;
    font-family: 'SFUI Bold' !important;
    color: #ced4da;
    font-size: 10px;
    font-weight: 100;
}

.secondry-number {
    font-family: 'SFUI Medium';
    color: #ced4da;
    font-size: 8px;
    font-weight: 100;
    padding-left: 4px;

}

.number-title {
    font-family: 'SFUI Medium';
    font-size: 10px;
    color: #ced4da;
}

.number-title small {
    font-family: 'SFUI Medium';
    font-size: 7px;
}

.active .main-number {
    /* color: #004d9a; */
}

.active .number-title {
    /* color: #333; */
}

.active .secondry-number {
    /* color: #333; */
    /* opacity: 0.3; */
}

.icon-wrapper {
    height: 50%;
    overflow: hidden;
}

.tile .tile-label {
    font-family: 'SFUI Light';
    text-transform: none;
}

.tile .icon {
    font-size: calc(80% + 1vmax);
}

.tile .tile-label {
    font-size: calc(60% + 0.3vmax);
    height: 50%;
    overflow: hidden;
}

.tile .active .tile-label {
    font-family: "Lato Regular";
    font-weight: bold;
    text-shadow: 0 0 1px #333;
}

.tile>div {
    display: flex;
    align-items: center;
    height: calc(100% - 8px);
    border: 2px solid transparent;
    border-radius: 10px;
}
.tile>div:hover{
    border: 2px solid #797777;
}

.active .tile>div {
    border: 1px solid #FFFFFF;
    /* background-color: #333; */
}
.active .tile.otb-tile>div{ 
    /* background-color: #8FD5EF; */
    /* font-weight: bold; */
}
.active .active-filter{
    font-weight: bold;
    font-family: 'Lato Bold';
    
}

.tile,
.otb-tile {
    min-height: 100%;
    height: calc((100vh - 126px)/5.87);
}

.otb-tile-row {
    min-height: 32px;
}

.otb-loader {
    color: #4d82b8;
    text-transform: capitalize;
    text-align: center;
    padding-top: 10px;
    font-size: 1rem;
    letter-spacing: 2px;
    font-family: "Lato Light";
}

@media only screen and (max-width:1199px) {
    .work-space-wrapper {
        height: calc(((100vh - 126px)/5) * 5);
    }
}

@media only screen and (min-width:1200px) {
    @media only screen and (min-height:700px){
        .container-pt-vh{
            padding-top: 10px !important;
        }
        .tile-main-number-row {
            padding-top: 6px;
            height: 10px;
        }
        .otb-container-main{
            padding-top: 5px;
        }
    }
    @media only screen and (min-height:800px){
        .container-pt-vh{
            padding-top: 18px !important;
        }
        .tile-main-number-row {
            padding-top: 6px;
            height: 5px;
        }
    }
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.7);
    }
    .work-space-wrapper {
        height: calc(((100vh - 126px)/5.7) * 5);
        overflow: hidden;
    }
    .tile .icon {
        font-size: calc(140% + .9vmax);
    }
    .tile .tile-label {
        padding-bottom: 8px;
    }
    .secondry-number{
        font-size: 9px;
    }
    .main-number {
        min-width: 180px;
        padding-top: 2px;
    }
}

@media only screen and (min-width:1300px) {
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.7);
    }
    .work-space-wrapper {
        height: calc(((100vh - 126px)/5.7) * 5);
        overflow: hidden;
    }
    .tile .icon {
        font-size: calc(140% + .9vmax);
    }
    .tile .tile-label {
        padding-bottom: 8px;
    }
    .container-pt-vh{
        padding-top: 10px !important;
    }
    .tile-main-number-row {
        padding-top: 6px;
        height: 10px;
    }
    .secondry-number{
        font-size: 9px;
    }
    .otb-container-main {
        padding-top: 10px;
    }
}

@media only screen and (min-width:1400px) {
    @media only screen and (min-height:700px){
        .otb-container-main{
            padding-top: 0px;
        }
        .container-pt-vh{
            padding-top: 10px !important;
        }
        .tile-main-number-row {
            padding-top: 4px;
            height: 10px;
        }
    }
    @media only screen and (min-height:800px){ 
        .otb-container-main{
            padding-top: 5px;
        }
        .container-pt-vh{
            padding-top: 15px !important;
        }
        .tile-main-number-row {
            padding-top: 6px;
            height: 10px;
        }
    }
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.86);
    }
    .tile .tile-label {
        font-size: calc(60% + 0.5vmax);
    }
    .tile .icon {
        height: 48px;
    }
    .main-number {
        font-size: calc(60% + 0.5vmax);
        min-width: 170px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(60% + 0.5vmax);  
        justify-content: unset;
        gap: 35px;
    }
    .number-title {
        font-size: calc(55% + 0.5vmax);   
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 13px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 35px;
    }
    .number-title small {
        font-size: 8px;
    }
    .ready-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 2px;
        right: 2px;
    }
    .incompatible-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 2px;
        right: 2px;
    }
    .intellimerged-icon{
        height: 17px !important;
        width: 17px !important;
    }
    .down-arrow {
        font-size: 11px;
    }
    .up-arrow {
        font-size: 11px;
    }
}

@media only screen and (min-width:1500px) {
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.9);
    }
    .tile .tile-label {
        font-size: calc(60% + 0.5vmax);
    }
    .tile .icon {
        height: 48px;
    }
    .main-number {
        font-size: calc(60% + 0.5vmax);
        min-width: 170px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(60% + 0.5vmax);  
        justify-content: unset;
        gap: 35px;
    }
    .number-title {
        font-size: calc(55% + 0.5vmax);   
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 13px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 35px;
    }
    .number-title small {
        font-size: 8px;
    }
    .container-pt-vh{
        padding-top: 15px !important;
    }
    .ready-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 2px;
        right: 2px;
    }
    .incompatible-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 2px;
        right: 2px;
    }
    .intellimerged-icon{
        height: 17px !important;
        width: 17px !important;
    }
    .down-arrow {
        font-size: 12px;
    }
    .up-arrow {
        font-size: 12px;
    }
}

@media only screen and (min-width:1600px) {
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.95);
    }
    .tile .tile-label {
        font-size: calc(60% + 0.5vmax);
    }
    .tile .icon {
        height: 48px;
    }
    .main-number {
        font-size: calc(60% + 0.5vmax);
        min-width: 170px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(60% + 0.5vmax);  
        justify-content: unset;
        gap: 35px;
    }
    .number-title {
        font-size: calc(55% + 0.5vmax);   
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 13px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 35px;
    }
    .number-title small {
        font-size: 9px;
    }
    .container-pt-vh{
        padding-top: 15px !important;
    }
    .ready-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 0px;
        right: 0px;
    }
    .incompatible-intellimerge-icon{
        width: 1.5em;
        height: 1.5em;
        top: 0px;
        right: 0px;
    }
    .intellimerged-icon{
        height: 17px !important;
        width: 17px !important;
    }
    .down-arrow {
        font-size: 12px;
    }
    .up-arrow {
        font-size: 12px;
    }
}

@media only screen and (min-width:1800px) {
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6.5);
    }
    .tile .tile-label {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 16px;
    }
    .tile .icon {
        height: 58px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 16px;
        min-width: 190px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(63% + 0.6vmax);  
        justify-content: unset;
        gap: 40px;
    }
    .number-title {
        font-size: calc(55% + 0.5vmax);   
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 16px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 40px;
    }
    .number-title small {
        font-size: 11px;
    }
    .container-pt-vh{
        padding-top: 18px !important;
    }
    .ready-intellimerge-icon{
        --size : 1.5em;
        --distance : 2px;
        width: var(--size);
        height: var(--size);
        top: var(--distance);
        right: var(--distance);
    }
    .incompatible-intellimerge-icon{
        --size : 1.5em;
        --distance : 2px;
        width: var(--size);
        height: var(--size);
        top: var(--distance);
        right: var(--distance);
    }
    .intellimerged-icon{
        height: 18px !important;
        width: 18px !important;
    }
    .down-arrow {
        font-size: 15px;
    }
    .up-arrow {
        font-size: 15px;
    }
    .otb-container-main {
        padding-top: 0px;
    }
}

@media only screen and (min-width:2000px) {
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6.5);
    }
    .tile .tile-label {
        font-size: calc(63% + 0.6vmax);
    }
    .tile .icon {
        height: 58px;
    }
    .main-number {
        font-size: calc(63% + 0.6vmax);
        min-width: 190px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(63% + 0.6vmax);  
        justify-content: unset;
        gap: 45px;
    }
    .number-title {
        font-size: calc(55% + 0.5vmax);   
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 15px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 45px;
    }
    .number-title small {
        font-size: 11px;
    }
    .container-pt-vh{
        padding-top: 20px !important;
    }
    .ready-intellimerge-icon{
        width: 2em;
        height: 2em;
        top: 0px;
        right: 0px;
    }
    .incompatible-intellimerge-icon{
        width: 2em;
        height: 2em;
        top: 0px;
        right: 0px;
    }
    .intellimerged-icon{
        height: 19px !important;
        width: 19px !important;
    }
    .down-arrow {
        font-size: 15px;
    }
    .up-arrow {
        font-size: 15px;
    }
}

@media only screen and (min-width:2880px) {

    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/5.9);
    }

    .work-space-wrapper {
        height: calc(((100vh - 126px)/5.9) * 5);
    }

    .tile .tile-label {
        font-size: calc(80% + 0.3vmax);
    }
}

@media only screen and (min-width:2500px) {
   
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6.8);
    }
    .tile>div {
        border-radius: 12px;
    }  
    .active .tile>div {
        border: 1.4px solid #FFFFFF;
    }
    .tile .tile-label {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 18px;
    }
    .tile .icon {
        height: 70px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 18px;
        min-width: 190px;
        padding-top: 8px;
    }
    .tile-title-row {
        font-size: calc(60% + 0.52vmax);  
        justify-content: unset;
        gap: 50px;
    }
    .number-title {
        font-size: calc(50% + 0.5vmax);   
        padding-top: 8px;
    }
    .secondry-number{
        font-size: 18px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 50px;
    }
    .number-title small {
        font-size: 13px;
    }
    .container-pt-vh{
        padding-top: 26px !important;
    }
    .ready-intellimerge-icon{
        width: 2em;
        height: 2em;
        top: 2px;
        right: 2px;
    }
    .incompatible-intellimerge-icon{
        width: 2em;
        height: 2em;
        top: 2px;
        right: 2px;
    }
    .intellimerged-icon{
        height: 23px !important;
        width: 22px !important;
    }
    .down-arrow {
        font-size: 15px;
    }
    .up-arrow {
        font-size: 15px;
    }
    .otb-container-main {
        padding-top: 5px;
        padding-left: 20px;
    }

    @media only screen and (min-height:1600px){
        .tile,
        .otb-tile {
            height: calc((100vh - 126px)/7);
        }
        .container-pt-vh{
            padding-top: 30px !important;
        }
        .tile-main-number-row {
            padding-top: 10px;
            height: 10px;
        }
        .otb-container-main{
            padding-top: 10px;
        }
        .main-number {
            font-size: 20px;
        }
    }
}

@media only screen and (min-width:2800px) {
   
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6);
    }
           
    .tile .tile-label {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 24px;
    }
    .tile .icon {
        height: 90px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 24px;
        min-width: 210px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(60% + 0.6vmax);
        justify-content: unset;
        gap: 55px;
    }
    .number-title {
        font-size: calc(60% + 0.5vmax);  
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 24px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 55px;
        padding-top: 22px;
        height: 9px;
    }
    .number-title small {
        font-size: 14px;
    }
    .container-pt-vh{
        padding-top: 36px !important;
    }
    .ready-intellimerge-icon{
        width: 2.5em;
        height: 2.5em;
        top: 0px;
        right: 0px;
    }
    .incompatible-intellimerge-icon{
        width: 2.5em;
        height: 2.5em;
        top: 0px;
        right: 0px;
    }
    .intellimerged-icon{
        height: 27px !important;
        width: 25px !important;
    }
    .down-arrow {
        font-size: 18px;
    }
    .up-arrow {
        font-size: 18px;
    }
    .otb-container-main {
        padding-top: 20px;
    }
}

@media only screen and (min-width:3400px) {
   
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6);
    }

    .tile>div {
        border-radius: 15px;
    }
           
    .tile .tile-label {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 22px;
    }
    .tile .icon {
        height: 85px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 22px;
        min-width: 210px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(50% + 0.57vmax);
        justify-content: unset;
        gap: 55px;
    }
    .number-title {
        font-size: calc(40% + 0.5vmax);  
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 22px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 55px;
        padding-top: 22px;
        height: 9px;
    }
    .number-title small {
        font-size: 14px;
    }
    .container-pt-vh{
        padding-top: 20px !important;
        padding-left: 40px;
    }
    .ready-intellimerge-icon{
        width: 2.5em;
        height: 2.5em;
        top: 0px;
        right: 0px;
    }
    .incompatible-intellimerge-icon{
        width: 2.5em;
        height: 2.5em;
        top: 0px;
        right: 0px;
    }
    .intellimerged-icon{
        height: 27px !important;
        width: 25px !important;
    }
    .down-arrow {
        font-size: 18px;
    }
    .up-arrow {
        font-size: 18px;
    }
    .otb-container-main {
        padding-top: 14px;
    }
}

@media only screen and (min-width:3800px) {
   
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6);
    }

    .tile>div {
        border-radius: 25px;
    }
    .active .tile>div {
        border: 1.5px solid #FFFFFF;
    }      
    .tile .tile-label {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 26px;
    }
    .tile .icon {
        height: 100px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 26px;
        min-width: 220px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(50% + 0.65vmax);
        justify-content: unset;
        gap: 55px;
    }
    .number-title {
        font-size: calc(40% + 0.6vmax);  
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 26px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 55px;
        padding-top: 26px;
        height: 35px;
    }
    .number-title small {
        font-size: 17px;
    }
    .container-pt-vh{
        padding-top: 20px !important;
        padding-left: 40px;
    }
    .ready-intellimerge-icon{
        width: 3em;
        height: 3em;
        top: 4px;
        right: 2px;
    }
    .incompatible-intellimerge-icon{
        width: 3em;
        height: 3em;
        top: 4px;
        right: 2px;
    }
    .intellimerged-icon{
        height: 35px !important;
        width: 33px !important;
        border-bottom-left-radius: 10px !important;
    }
    .down-arrow {
        font-size: 18px;
    }
    .up-arrow {
        font-size: 18px;
    }
    .otb-container-main {
        padding-top: 60px;
    }
}

@media only screen and (min-width:5000px) {
   
    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6);
    }

    .tile>div {
        border-radius: 25px;
    }
    .active .tile>div {
        border: 1.5px solid #FFFFFF;
    }      
    .tile .tile-label {
        font-size: 40px;
    }
    .tile .icon {
        height: 150px;
    }
    .main-number {
        /* font-size: calc(63% + 0.6vmax); */
        font-size: 40px;
        min-width: 250px;
        padding-top: 5px;
    }
    .tile-title-row {
        font-size: calc(50% + 0.65vmax);
        justify-content: unset;
        gap: 55px;
    }
    .number-title {
        font-size: calc(40% + 0.6vmax);  
        padding-top: 5px;
    }
    .secondry-number{
        font-size: 38px;
    }
    .tile-main-number-row {
        justify-content: unset;
        gap: 55px;
        padding-top: 26px;
        height: 35px;
    }
    .number-title small {
        font-size: 22px;
    }
    .container-pt-vh{
        padding-top: 44px !important;
        padding-left: 50px;
    }
    .ready-intellimerge-icon{
        width: 3em;
        height: 3em;
        top: 4px;
        right: 2px;
    }
    .incompatible-intellimerge-icon{
        width: 3em;
        height: 3em;
        top: 4px;
        right: 2px;
    }
    .intellimerged-icon{
        height: 35px !important;
        width: 33px !important;
        border-bottom-left-radius: 10px !important;
    }
    .down-arrow {
        font-size: 30px;
    }
    .up-arrow {
        font-size: 30px;
    }
    .otb-container-main {
        padding-top: 60px;
        padding-left: 80px;
    }
    .work-space-wrapper {
        height: calc(((100vh - 126px)/6) * 5);
    }
}

/* @media only screen and (min-width:4300px) {

    .tile,
    .otb-tile {
        height: calc((100vh - 126px)/6);
    }

    .work-space-wrapper {
        height: calc(((100vh - 126px)/6) * 5);
    }

    .tile .icon {
        font-size: calc(100% + 2.5vmax);
    }

    .tile .tile-label {
        font-size: calc(60% + 0.55vmax);
    }
} */

.main-div{
    width: 100%;
}
.div-left{
    width: 19%;
    min-height: 10px;
    height: 15px;
    float: left;
}
.div-right{
    width: 80%;
    min-height: 10px;
    height: 15px;
    float: right;
    text-align: center;
    /* font-size: calc(.5em + 1vw); */
}
.inner-right-div{
    float: left;
    width: 25%;
    text-align: center;
    font-size: calc(.5em + 0.4vw);

}

.inner-right-div1{
    float: left;
    width: 25%;
    color: #8fd6f0;
    /* font-size: 17px; */
    font-weight: 100;
    text-align: center;
    font-size: calc(.5em + 0.6vw);

}

.inner-right-div2{
    float: left;
    width: 25%;
    text-align: center;
    margin-top: 4px;
    /* font-size: 11px; */
    font-size: calc(.5em + 0.3vw);

}

.main-div-seprator{
    min-height: 10px;
    height: 10px;
}

.mt-10{
    padding-top: 10px;
    height: 100%;
    min-height: 100%;
}

/* .otb-fs{
    font-size: calc(50% + 0.40vmax) !important;
}

.otb-number-fs{
    font-size: calc(40% + 0.40vmax) !important;
}

.otb-number-yoy{
    font-size: calc(40% + 0.40vmax) !important;
} */

.container-pt-vh{
    padding-top: 2px ;
}

.intellimerged-icon{
    background-color: #FFFFFF;
    right: 0px;
    border-top-right-radius: 10px;
    top: 0px;
    border-bottom-left-radius: 5px;
    color: #01A4EF;
    height: 15px;
    width: 15px;
}