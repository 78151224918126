.react-dynamic-table {
    min-height: calc((487vh - 1230px)/5.87);

    .header_col {
        line-height: initial;

        .filter-position-r {
            padding: 0px 10px 0 10px;

            .column-sorting {
                left: 0px;
                top: -5px;
                height: 50px;
            }

            
        }



        .column-sorting {
            left: 0px;
            top: -14px;
            min-width: auto;
        }

        &.header-col-date {
            left: 0px;
        }

        &.header-col-dow{
            left: 76px;
        }
    }

    .secondary-header-cell {
        padding: 12px 15px;
    }

    .header-grey {
        background-color: #B2B2B2 !important;
        width: 200px !important;
    }

    .border-right {
        border-right: 1px solid #ddd !important;
    }

    .border-left {
        border-left: 1px solid #ddd !important;
    }

    .dow-style {
        text-align: left !important;
        padding-left: 25px !important;
    }

    .dow-style-comp-date {
        text-align: left !important;
        padding-left: 40px !important;
        // font-weight: bold;
    }

    .segment-td-style {
        text-align: left !important;
        padding-left: 10px !important;
        // left: 40px !important;
    }

    .table-row {
        height: 24px;

        .data-cell {

            input.pricing_Override,
            input.current_differential,
            input.SellRate {
                width: 70px;
                height: 17px;
                box-sizing: border-box;
            }
            &.data-cell-dow {
                left: 76px !important;
            }
        }

        &:nth-of-type(even) {
            .data-cell {
                background: #ebebeb;
            }

        }

        &:nth-of-type(odd) {
            .data-cell {
                background: #fff;
            }
        }

        &:last-child {
            .data-cell {
                background: #b1e2f4;
                color: #333;
                // text-transform: uppercase;
            }
        }

    }

    .table-row:not(.footer-row):hover td {
        background: #B2E6FD !important;
    }

    .table-row.focus td {
        background: #d9f2fe !important;
    }

    // .table-row:hover td { background: transparent; }

    .data-cell {
        font-size: 12px;
        line-height: initial;
        padding: 5px 11px;
        text-align: center;
    }

    .header_row {
        .date_subcolumn {
            left: 0;
            position: sticky;
            padding: 5px 0;
            width: 60px;

            &:nth-child(2) {
                left: 66px;
            }
        }

        .date_subcolumn_segment {
            left: 0;
            position: sticky;
            padding: 5px 0;
            width: 150px;
            min-width: 150px !important;

            &:nth-child(2) {
                left: 66px;
            }
        }

        .header-blue{
            font-size: 12px !important;
        }
        .segment-class{
            width: 130px !important;
        }
    }

    &.tile-active-hotel_summary,
    &.tile-active-market_summary {
        .header_col.date_subcolumn {
            min-width: 90px;
        }
    }


    .footer-row {
        .data-cell {
            font-weight: bold;
        }
    }

    .total-row {
        height: 24px;

        &:nth-of-type(even) {
            .data-cell {
                background: #B2E6FD;
            }

        }

        &:nth-of-type(odd) {
            .data-cell {
                background: #fff;
            }
        }

        &:last-child {
            .data-cell {
                background: #B2E6FD;
                color: #333;
                // text-transform: uppercase;
            }
        }

        .data-cell {
            font-weight: bold;
        }
    }

    .even-row {
        background: #ebebeb !important;

        .data-cell {
            background: #ebebeb !important;
        }
    }

    .total-row {
        .data-cell {
            background: #B2E6FD !important;
        }

        background-color:#B2E6FD !important;
        font-weight: bold !important;
        font-size: 12px !important;
    }

    .total-col {
        background-color: #B2E6FD !important;
        font-weight: bold !important;
        font-size: 12px !important;
    }


    .text-align-right {
        text-align: right !important;
        padding-right: 50px !important;

    }
    .filter-position-r {
        position: relative !important;
        height: 100% !important;
        // min-width: 100%;
        padding: 6px 20px 6px 20px;

    }

    .date-col {
        position: relative !important;
        height: 100% !important;
        min-width: 0px !important;
    }

    .filter-menus {
        position: absolute !important;
        right: -15px !important;
        top : -12px !important;
        font-size: 17px !important;
        color: #e6e6e6 !important;
        cursor: pointer;
    }

    .list-item-icon {
        min-width: 35px !important;
    }

    .list-item-text>span {
        font-size: 0.875 rem !important;
    }

    .filter-main {
        position: absolute;
        top: 0px;
        right: 2px;
    }

    .css-10hburv-MuiTypography-root {
        font-size: 0.9 rem !important;
    }

    label {
        margin-bottom: -7px !important;
    }

    //Sorting component
    .column-sorting {
        position: absolute;
        left: 0px;
        top: -10px;
        // width: 30px;
        min-width: auto;
        padding: 0;
        margin: 0px;
        height: 45px;

        &.order-desc {
            .order-arrow-down {
                opacity: 1;
            }
        }

        &.order-asc {
            .order-arrow-up {
                opacity: 1;
            }
        }

        .order-arrow {
            position: absolute;
            color: #fff;
            opacity: 0.2;

            &.order-arrow-up {
                top: 0;
            }

            &.order-arrow-down {
                bottom: 0px;
            }
        }

    }

    // restriction type classes
    .btn-open {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
        text-transform: uppercase;
    }

    .btn-close {
        color: #000;
        background-color: rgba(220, 53, 69, 0.3);
        border-color: #dc3545;
        border: 1px solid #dc3545 !important;
        text-transform: uppercase;
    }

    .btn-cta {
        color: #000 !important;
        background-color: rgba(150, 97, 48, 0.3) !important;
        border-color: #966130 !important;
        border: 1px solid #966130 !important;
        text-transform: uppercase;

    }

    .btn-ctd {
        color: #000 !important;
        background-color: rgba(203, 8, 196, 0.3) !important;
        border: 1px solid #cb08c4 !important;
        text-transform: uppercase !important;
    }

    .btn-cls {
        color: #000;
        background-color: rgba(220, 53, 69, 0.3);
        border-color: #dc3545;
        border: 1px solid #dc3545 !important;
        text-transform: uppercase;
    }

    .btn-min_los {
        color: #000;
        background-color: rgba(237, 145, 15, 0.3);
        border-color: #eea236;
        border: 1px solid #eea236 !important;

        text-transform: uppercase;

    }

    .btn-max_los {
        color: #000;
        background-color: rgba(23, 162, 184, 0.3);
        border-color: #17a2b8;
        border: 1px solid #17a2b8 !important;
        text-transform: uppercase;
    }

    .btn-cancel {
        color: white;
        background-color: #d2d2d2;
        border-color: #f8f9fa;
        text-transform: uppercase;
    }

    .rest-dots-one {
        position: relative !important;
    }

    .rest-dots-two {
        position: absolute !important;
        font-size: 19px !important;
    }

    .chip {
        float: left;
        margin: 0px 5px 0px 0px;
        padding: 0px 5px 0px 5px;
        // border: 1px solid #000;
        border-radius: 5px;
        font-size: 10px !important;
        line-height: 12px !important;
        font-family: "Lato Regular";

    }

}

.restrictions-details {

    // restriction type classes
    .btn-open {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
        text-transform: uppercase;
    }

    .btn-close {
        color: #000;
        background-color: rgba(220, 53, 69, 0.3);
        border-color: #dc3545;
        border: 1px solid #dc3545 !important;
        text-transform: uppercase;
    }

    .btn-cta {
        color: #000 !important;
        background-color: rgba(150, 97, 48, 0.3) !important;
        border-color: #966130 !important;
        border: 1px solid #966130 !important;
        text-transform: uppercase;

    }

    .btn-ctd {
        color: #000 !important;
        background-color: rgba(203, 8, 196, 0.3) !important;
        border: 1px solid #cb08c4 !important;
        text-transform: uppercase !important;
    }

    .btn-cls {
        color: #000;
        background-color: rgba(220, 53, 69, 0.3);
        border-color: #dc3545;
        border: 1px solid #dc3545 !important;
        text-transform: uppercase;
    }

    .btn-min_los {
        color: #000;
        background-color: rgba(237, 145, 15, 0.3);
        border-color: #eea236;
        border: 1px solid #eea236 !important;

        text-transform: uppercase;

    }

    .btn-max_los {
        color: #000;
        background-color: rgba(23, 162, 184, 0.3);
        border-color: #17a2b8;
        border: 1px solid #17a2b8 !important;
        text-transform: uppercase;
    }

    .btn-cancel {
        color: white;
        background-color: #d2d2d2;
        border-color: #f8f9fa;
        text-transform: uppercase;
    }

    .rest-dots-one {
        position: relative !important;
    }

    .rest-dots-two {
        position: absolute !important;
        font-size: 19px !important;
    }

    .chip {
        float: left;
        margin: 5px 5px 0px 0px;
        padding: 0px 5px 0px 5px;
        // border: 1px solid #000;
        border-radius: 5px;
        font-size: 10px !important;
        line-height: 12px !important;
        font-family: "Lato Regular";
    }

    .MuiList-padding {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .restriction-box {
        min-width: 350px;
        min-height: 125px;
        border: 1px solid #333333;
        border-radius: 5px;
    }

    .restriction-box-title {
        min-width: 270px;
        min-height: 30px;
        border-radius: 5px;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #7A7A7A;
        margin: 2px;
        padding: 6px 0px 2px 15px;
        font-size: 14px !important;
    }

    .restriction-table-row {
        border-bottom: 1px solid #000 !important;
        text-align: left !important;
    }

    .table-heading {
        font-size: 12px !important;
        font-weight: bold;
    }
}

.rateplan-details{
    .MuiList-padding {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .rateplan-box {
        min-width: 350px;
        min-height: 125px;
        border: 1px solid #7A7A7A;
        border-radius: 4px;
    }

    .rateplan-box-title {
        min-width: 270px;
        min-height: 30px;
        border-radius: 4px 4px 0px 0px;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #7A7A7A;
        margin: 0px;
        padding: 6px 0px 2px 8px;
        font-size: 14px !important;
    }

    .rateplan-table-row {
        border-bottom: 1px solid #ddd !important;
        text-align: left !important;
        background-color: #efefef !important;
        color: #606060 !important;
    }

    .table-heading {
        font-size: 12px !important;
        font-weight: bold;
        color: #606060 !important;
    }
    .table-heading-occ{
        font-size: 12px !important;
        font-weight: bold;
        color: #606060 !important;
        background-color: #ccc;
    }

    .rateplan-row-col-content > th{
        color: #606060;
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 12px;
    }
    .border-right{
        border-right: 1px solid #ddd;
    }
}

.rate-plan-code{
    background-color: #e2e2e2;
    color: #2580b4;
    float: left;
    margin-bottom: 3px;
}

.logs{
    
    float: left;
    margin-bottom: 3px;
    margin-left: 4px;
}

.logs .room-code {
    color: #2580B4;
    min-width: 50px;
    // background: #E2E2E2;
    height: 32px;
    margin: 0;
    border-radius: 21px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-left: 10px;
    line-height: 30px;
    float: left;
}

.logs .rate-value{
    font-weight:500;
}

.space{
    margin: 20px !important;
}