$assets: "../../../public/";

.popup-modal {
    &.restrictions-update {
        background: #fff;
        position: absolute;
        top: 50%;
        // left: 50%;
        transform: translate(-50%, -50%);
        //min-height: 52vh !important;
        height: auto;
        box-shadow: 0 0 10px #333;
        border-radius: 5px;
        outline: none;
        width: 22vw;
        background-color: #efefef;


        .text-green {
            color: #80CF46;
        }

        .modal-titlebar {
            background-color: #333333;
            font-size: 16px;
            color: #fff;
            height: 40px;
            padding: 0 16px;
            line-height: 40px;
            text-transform: uppercase;
            z-index: 10;
            position: relative;

            .right-cta {
                text-align: right;
            }
        }

        .footer-block {
            background-color: #333333;
            min-height: 45px;
            width: 100%;
            line-height: 45px;
            margin-bottom: -1px;
            z-index: 10;
            position: absolute;
            bottom: 0;
        }

        .status-column {
            width: 160px;
        }

        .icon-btn {
            min-width: auto;
        }

        // &.modal-pricing {
        .axn-btn {
            border: 1px solid #80CF46;
            height: 27px;
            color: #fff;
            padding: 5px 15px;

            &:disabled {
                opacity: 0.7;
            }
        }

        .restriction-row {
            margin: 10px 16px;
        }

        .stay-date {
            font-size: 14px;
        }

        .oval {
            background-color: #fff;
            padding: 3px;
            border: 1px solid #efefef;
            border-radius: 30px;
        }

        .res-label {
            padding-left: 16px;
            text-transform: uppercase;
            line-height: 26px;
        }

        .switch {
            &.cta {
                .MuiSwitch-thumb {
                    background-color: #C0B0A2;
                    // border:1px solid #956030;
                }

                .Mui-checked {
                    .MuiSwitch-thumb {
                        background-color: #FFF;
                        border-color: #fff;
                    }
                }

                .MuiSwitch-track {
                    border: 1px solid #956030;
                }

                .Mui-checked+.MuiSwitch-track {
                    background-color: #C0B0A2;
                    border: 1px solid #956030;
                }
            }

            &.ctd {
                .MuiSwitch-thumb {
                    background-color: #D39DCF;
                    // border:1px solid #cb08c4;
                }

                .MuiSwitch-track {
                    border: 1px solid #cb08c4;
                }

                .Mui-checked {
                    .MuiSwitch-thumb {
                        background-color: #FFF;
                        border-color: #fff;
                    }
                }

                .Mui-checked+.MuiSwitch-track {
                    background-color: #D39DCF;
                    border: 1px solid #cb08c4;
                }
            }

            &.close {
                .MuiSwitch-thumb {
                    background-color: #D8A6A8;
                    // border:1px solid #DC3444;
                }

                .MuiSwitch-track {
                    border: 1px solid #DC3444;
                }

                .Mui-checked {
                    .MuiSwitch-thumb {
                        background-color: #FFF;
                        border-color: #fff;
                    }
                }

                .Mui-checked+.MuiSwitch-track {
                    background-color: #D8A6A8;
                    border: 1px solid #DC3444;
                }
            }
        }

        .field {
            background-color: #fff;
            border-radius: 4px;

            // height: 26px;
            .MuiInputBase-root {
                height: 34px;
            }

            &.min-los {
                background-color: #DCBFA0;

                .MuiOutlinedInput-notchedOutline {
                    border-color: #EEA136;
                }
            }

            &.max-los {
                background-color: #A3C4CB;

                .MuiOutlinedInput-notchedOutline {
                    border-color: #14A2B8;
                }
            }

            // #DCBFA0
        }



        .btn-push {
            &:before {
                display: inline-block;
                content: " ";
                width: 20px;
                height: 17px;
                background: url($assets + "assets/img/upload.svg") 0 0 no-repeat;
                background-size: cover;
                margin: 0 5px;
            }

            &:hover {
                border: 1px solid #80CF46;
            }
        }


        .Rate-Push-DownloadButton {
            color: #fff;
            stroke: #333;
            margin-left: 10px;

        }

        .rate-push-table {
            thead {
                th {
                    background: #606060;
                    top: 0px;
                    margin-top: 0;
                    border-top: 1 solid #606060;
                    color: #fff;
                }
            }

            .ratepush-tbody {
                >tr:nth-of-type(odd) {
                    background: #FAFAFA;
                }
            }
        }

        .upload-button {
            // background: url("/public/assets/img/uploadblack.svg") no-repeat;
            width: 18px;
            min-height: 17px;
        }

        .error {
            background: url($assets + "assets/img/exclamation.svg") no-repeat;
            width: 18px;
            min-height: 18px;
        }

        .push-delete {
            background: url($assets + "assets/img/close.svg") no-repeat;
            width: 14px;
            min-height: 14px;
        }

        .in-progress {
            background: url($assets + "assets/img/checkmark.svg") no-repeat;
            width: 14px;
            min-height: 14px;
        }

        .task-complete {
            background: url($assets + "assets/img/task.svg") no-repeat;
            width: 32px;
            min-height: 32px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
        }

        // }

        .btn-ratepush {
            &>span {
                background-color: #80CF46;
                color: #181818;
            }

        }

        .success-popup {
            background: #000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 65vh;
            min-height: 65vh !important;
            box-shadow: 24;
            border-radius: 5px;
            color: #fff;
            z-index: 99;
            opacity: 0.7;


        }
 
        .rate-push-success {
            background: #EFF8EE;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20vw;
            height: 18vh;
            min-height: 18vh !important;
            box-shadow: 22;
            border-radius: 5px;
            color: #fff;
            z-index: 99;

            .rate-push-success-container {
                text-align: center;
                padding: 20px;
                color: #333;
                text-transform: uppercase;
                font-size: 12px;

                .button-green {
                    background-color: #80CF46;
                    margin-top: 15px;
                    color: #333;
                }

                .success-text {
                    font-size: 14px;
                    font-weight: bold;
                    padding-bottom: 10px;
                }
            }

        }
    }
    &.restrictions-push{
        width: 900px;
        .success-popup  {
            width: 100%;
        }
        .chip.removed{
            text-decoration: line-through;
        }
    }
}

.res-rules-display {
    white-space: nowrap;
    .res-rule {
        padding: 2px 4px;
        font-size: 0.88em;
        border-radius: 2px;
        line-height: .8;
        vertical-align: middle;
        margin-right: 5px;
        text-transform: uppercase;
        color: #000;
        border: 1px solid transparent;
        border-radius: 15px;
        &.chip{ 
            // font-size: 11px;
            padding: 0px 5px 0px 5px;
            border-radius: 15px;
            margin: 2px;
            height: 20px;

            &.MuiChip-deletable{
                padding: 0 0px 0 5px;
            }
            .MuiChip-label{
                padding: 0px 0px;
            }
            .MuiChip-deleteIcon{
                margin-left: 5px;
                margin-right: 2px;
                font-size: 18px;
            }
            .res-cta{
                background-color: rgba(150, 97, 48, 0.3);
                border-color: #966130;
            }
        }
        &.res-cta {
            background-color: rgba(150, 97, 48, 0.3);
            border-color: #966130;
        }

        &.res-ctd {
            background-color: rgba(203, 8, 196, 0.3);
            border-color: #cb08c4;
        }

        &.res-cls {
            background-color: rgba(220, 53, 69, 0.3);
            border-color: #dc3545;
        }

        &.res-min_los {
            background-color: rgba(237, 145, 15, 0.3);
            border-color: #eea236;
        }

        &.res-max_los {
            background-color: rgba(23, 162, 184, 0.3);
            border-color: #17a2b8;
        }
        &.removed{
            background-color: #ccc;
            border-color: #606060;
        }
    }
}

@media only screen and (min-height:500px){
    .popup-modal {
        &.restrictions-update {
            min-height: 68vh !important;
        }
    }
}
@media only screen and (min-height:600px){
    .popup-modal {
        &.restrictions-update {
            min-height: 66vh !important;
        }
    }
}
@media only screen and (min-height:700px){
    .popup-modal {
        &.restrictions-update {
            min-height: 56vh !important;
        }
    }
}
@media only screen and (min-height:800px){
    .popup-modal {
        &.restrictions-update {
            min-height: 49vh !important;
        }
    }
}
@media only screen and (min-height:900px){
    .popup-modal {
        &.restrictions-update {
            min-height: 44vh !important;
        }
    }
}
@media only screen and (min-height:1000px){
    .popup-modal {
        &.restrictions-update {
            min-height: 37vh !important;
        }
    }
}