@charset "UTF-8";

@font-face {
  font-family: "lodgiq-icons";
  src: url("fonts/lodgiq-icons.svg#lodgiq-icons") format("svg");
  src: url("fonts/lodgiq-icons.svg#lodgiq-icons") format("svg"),
    url("fonts/lodgiq-icons.woff") format("woff"),
    url("fonts/lodgiq-icons.ttf") format("truetype"),
    url("fonts/lodgiq-icons.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "lodgiq-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "lodgiq-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .icon-icon-forecast:before {  
  content: "\e901";
} */
.icon-icon-onthebooks:before {
  content: "\e902";
}

/* .mobile-view .icon-icon-forecast:before { 
  content: "\78";
} */
.mobile-view .icon-icon-onthebooks:before {
  content: "\78";
}

/*.icon-pricing-logo:before {
  content: "\e900";
}*/
/* .icon-icon-pricing-1:before {
  content: "\34";
} */
.icon-icon-vac-rental:before {
  content: "\32";
}

.icon-icon-exchange:before {
  content: "\33";
}

/* .icon-icon-budget:before {
  content: "\78";
} */
.icon-bell-o:before {
  content: "\61";
}

.icon-bell:before {
  content: "\62";
}

.icon-building:before {
  content: "\63";
}

.icon-building-o:before {
  content: "\64";
}

.icon-icon-property:before {
  content: "\65";
}

.icon-icon-expand:before {
  content: "\68";
}

.icon-icon:before {
  content: "\69";
}

.icon-icon-alert-bell:before {
  content: "\6a";
}

.icon-icon-zoom-out:before {
  content: "\6b";
}

.icon-icon-arrow-down:before {
  content: "\6d";
}

/* .icon-icon-booking-pace:before {
  content: "\6e";
} */
/* .icon-icon-comp-set:before {
  content: "\6f";
} */

.icon-icon-events:before {
  content: "\70";
}

.icon-icon-ex-point-inactive:before {
  content: "\71";
}

/* .icon-icon-flights-default:before {
  content: "\72";
} */

.icon-icon-flights-default {
  background: url("flight.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 95%;
}

/* .icon-icon-groups:before {
  content: "\73";
} */
/* .icon-icon-marke:before {
  content: "\74";
} */
/* .icon-icon-pickup:before {
  content: "\78";
} */

.icon-icon-support {
  /* margin-top: 4%; */
  margin-left: 0;
  background: url(icon-support.png) no-repeat 50% 0;
  background-size: calc(6vh + 2vw);
}

/* .mobile-view .icon-icon-pricing-1:before {
  content: "\79";
} */
.icon-icon-target-inactive:before {
  content: "\7a";
}

.icon-icon-upload-rates:before {
  content: "\41";
}

.icon-icon-weather:before {
  content: "\43";
}

.icon-icon-zoom-in:before {
  content: "\44";
}

.icon-table-view-icon:before {
  content: "\46";
}

.icon-icon-news:before {
  content: "\47";
}

/* .icon-icon-budget:before {
  content: "\8369";
} */
.icon-notebook-streamline:before {
  content: "\49";
}

/*.icon-icon-vac-rental:before {
  content: "\4a";
}*/
.icon-icon-news-alt:before {
  content: "\45";
}

.active .icon-icon-news-alt {
  /*  background: url("../img/controls-active.png") center center no-repeat transparent;
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: 100% auto;*/
}

.icon-icon-news-alt {
  /*  background: url("../img/controls.png") center center no-repeat transparent;
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: auto 100%; */
}

.icon-icon-pricing:before {
  content: "\66";
}

.icon-ios-star:before {
  content: "\6c";
}

.icon-icon-pc {
  background: url("pricing-calendar-icon.png") center 5px no-repeat transparent;


  background-size: auto 75%;
  height: 10vh;
  width: 100%;
  display: inline-block;
}

.icon-flash-report {
  background: url("icon-flash-report.svg") center 5px no-repeat transparent;
  background-size: auto 95%;
  height: 5.7vh;
  width: 100%;
  display: inline-block;
}

.active .icon-flash-report {
  background: url("icon-flash-report-active.svg") center 5px no-repeat transparent;
}

/* .icon-market-sell-rates{
  background: url("market-sell-reates.svg") center center no-repeat transparent;
  display: inline-block;
  background-size: auto 100%;
  height: 5vh; width: 100%;
} */
/* .active .icon-market-sell-rates {
  background: url("market-sell-reates-active.svg") center center no-repeat transparent;
  background-size: auto 100%;
} */

.icon-hotel-summary {
  background: url("icon-hotel-analysis.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 80%;
}

.active .icon-hotel-summary {
  background: url("icon-hotel-analysis.svg") center center no-repeat transparent;
  background-size: auto 80%;
  height: 40px;
}

/* .icon-market-analysis{
  background: url("icon-market-analysis.svg") center center no-repeat transparent;
  display: inline-block;
  height: 5.1vh;
  width: 100%;
  background-size: auto 80%;
} */
/* .active .icon-market-analysis {
  background: url("icon-market-analysis-active.svg") center center no-repeat transparent;
  background-size: auto 80%;
} */
.icon-icon-pricing-1 {
  background: url("room-1.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 95%;
}

.icon-icon-groups {
  background: url("icon-group-pricing.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-forecast {
  background: url("forcast.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-budget {
  background: url("budget.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 90%;
}

.icon-market-analysis {
  background: url("market-summery.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-marke {
  background: url("market.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 100%;
}

.icon-market-sell-rates {
  background: url("market-sell-rate.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-booking-pace {
  background: url("booking-curve.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-pickup {
  background: url("pickup.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-restrictions {
  background: url("restrictions.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.icon-icon-comp-set {

  background: url("icon-compset.svg") center center no-repeat transparent;
  display: inline-block;
  height: 40px;
  width: 100%;
  background-size: auto 85%;
}

.active .MuiBox-root .css-8pp36h {

  border: 2px solid #8FD5EF;
  background-color: #333;


}

