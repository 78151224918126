.smart-date-picker {
    position: relative;

    label {
        color: #333;
        line-height: normal;
        text-align: left;
        text-transform: uppercase;
        font-size: 75%;
        margin: 0px;
        padding: 0;
        margin-top: 5px;
        font-size: 75%;
        font-weight: bold;
        color: #afafaf;
        min-height: 18px !important;
    }

    button {
        padding: 4px 4px;
        font-size: 13px;
        min-height: 25px;
        height: auto;
        border-radius: 0px;
        border: none;
        outline: none;

        display: inline-block;
        color: #01A4EF;

        &.btn-stay-picker {
            padding-right: 4px;
            min-width: 210px;
            text-align: left;


        }

        &.btn-stay-range {
            background: #E4E4E4;
        }

        &.shift-prev {
            float: left;
            &:disabled{
                opacity: 0.4;
                cursor: not-allowed;
            }
        }

        &.shift-next {
            float: right;
            &:disabled{
                opacity: 0.4;
                cursor: not-allowed;
            }
        }
    }

    .popover {
        position: absolute;
        z-index: 99;
        background-color: white;

        hr {
            margin: 0 -10px 10px -10px;
            color: #333;
        }

        .btn-info {
            color: #fff;
            background-color: #5bc0de;
            border-color: #46b8da;
            padding: 6px 12px;
        }

        .range-segments {
            margin-top: 8px;

            .active {
                background: #8FD5EF;
                // color: #E4E4E4;
            }

            label.btn {
                display: block;
                font-weight: 100;
                position: relative;
                padding-left: 25px;
                box-shadow: none;
                margin-right: 20%;

                &.active {
                    color: #01A4EF;

                    &:after {
                        content: " ";
                        border: 1px transparent;
                        border-radius: 10px;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        position: absolute;
                        left: 4px;
                        top: 12px;
                        width: 8px;
                        height: 8px;
                        background: #01A4EF;
                    }
                }

                &:before {
                    content: " ";
                    border: 1px solid #01A4EF;
                    border-radius: 20px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    position: absolute;
                    left: 0px;
                    top: 8px;
                    width: 16px;
                    height: 16px;

                }
            }
        }

        #week-picker-calendar {
            .react-calendar {

                button.react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day,
                button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__month-view__days__day,
                button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
                    background-color: #5bc0de;
                    color: white;
                    border-radius: 0px;
                }
            }

        }

        #month-picker-calendar {
            .react-calendar {
                .react-calendar__navigation {
                    button.react-calendar__navigation__label {
                        padding: 1px 86px;
                    }
                }
            }
        }

        .react-calendar {
            padding: 12px 8px;
            text-align: center;

            button.react-calendar__tile.react-calendar__year-view__months__month {
                // month calendar style 
                height: 53px;
                text-transform: uppercase;
                padding: 6px;
                color: #333;
                background-color: #fff;
                font-size: 14px;
            }

            button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
                border-color: #2e6da4;
                color: #fff;
                background-color: #337ab7;

            }

            button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__year-view__months__month,

            button.react-calendar__tile.react-calendar__tile--active.react-calendar__year-view__months__month,
            //range,
            button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeEnd.react-calendar__year-view__months__month //range end

                {
                background-color: #5bc0de;
                color: white;
            }

            button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
                background-color: #5bc0de;
                border-color: #46b8da;
                color: white;

            }

            button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
                background-color: #5bc0de;
                border-color: #5bc0de;
                color: white;
            }

            button {
                &.react-calendar__tile {
                    &.react-calendar__month-view__days__day {
                        &.react-calendar__month-view__days__day--neighboringMonth {
                            color: #777;
                        }
                    }
                }
            }

            .react-calendar__navigation {
                display: block !important;

                button {
                    padding: 1px 6px;
                    margin: initial;

                    &.react-calendar__navigation__label {
                        // padding: 6px 70px;
                        // padding-top: 6px;
                        padding-bottom: 6px;
                    }

                    &.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
                        position: relative;
                        top: 1px;
                        float: left;
                        padding-left: 10px;
                        display: inline-block;
                        font-family: 'Glyphicons Halflings';
                        font-style: normal;
                        font-weight: normal;
                        color: white;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &:before {
                            content: "\e079";
                            color: #afafaf;
                        }
                    }

                    &.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
                        position: relative;
                        top: 1px;
                        float: right;
                        display: inline-block;
                        font-family: 'Glyphicons Halflings';
                        font-style: normal;
                        font-weight: normal;
                        color: white;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &:before {
                            content: "\e080";
                            color: #afafaf;
                        }
                    }
                }

                button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
                button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
                    display: none;
                }
            }

            .react-calendar__month-view__weekdays__weekday {
                color: #afafaf;
                text-decoration: none;
                border-bottom: 0;
                text-transform: uppercase;
                font-size: .7em;
                font-weight: bold;
                text-align: center;

            }

            button.react-calendar__tile.react-calendar__month-view__days__day {
                //days range and single style
                padding: 6px;
                color: #333;
                background-color: #fff;
                font-size: 14px;
                line-height: 1.42857143;
                border-radius: 4px;
            }

            .react-calendar__year-view {
                width: 95%;
                margin-left: 7px;
                display: inline-block;

            }

            .react-calendar__month-view {
                // width: 93%;
                // margin-left: 12px;        
                display: block;

                .react-calendar__month-view__weekdays {

                    abbr[title],
                    abbr[data-original-title] {
                        border-bottom: none;
                        text-decoration-line: none;
                    }
                }
            }
        }
    }

}

.date-picker-wrapper {
    width: 280px;
    min-height: 545px;

    .date-picker {
        .MuiInputBase-input {
            padding: 5px;
        }
    }

    .calendar-box {
        margin-left: -5px;
        margin-right: -12px;
        margin-top: 10px;
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
        background-color: #d2eef9;
        border-radius: 0;
        color: #004d9a;

        &.react-calendar__tile--now {
            background-color: #d2eef9;
        }
    }

    .react-calendar__tile:hover {
        background-color: #8fd5ef;
        border-radius: 0;
        color: #004d9a;
    }

    // react-calendar__tile react-calendar__tile--now react-calendar__tile--active react-calendar__tile--range react-calendar__month-view__days__day

    .react-calendar__tile {
        &.react-calendar__tile--now {
            background-color: none;
            border: 1px solid #004d9a;
            border-radius: 0;
            color: #004d9a;

            border-radius: 0;

            &.react-calendar__tile--active {
                background-color: #8fd5ef;
            }
        }


    }
    .disabled-range-type{
        position: relative;
        &::before{
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.7);
            cursor: not-allowed;
        }
    }
}

.range-segments {
    .active {
        background: #8FD5EF;
        // color: #E4E4E4;
    }

    .days-num-btn {
        position: relative;
    }

    .days-num {
        font-size: 16px;
        font-family: "Lato Bold";
        display: block;
        clear: both;
        margin-top: 4px;
        margin-bottom: 3px;
    }

    .this-month {
        font-size: 15px;
        font-family: "Lato Regular";
        display: block;
        clear: both;
        margin-top: 0px;
        margin-bottom: 3px;
    }

    .days-label {
        font-size: 9px;
        font-family: "Lato Regular";
        display: block;
        margin-left: 4px;
        clear: both;
        position: absolute;
        bottom: 0;
    }

    .next-label {
        font-size: 9px;
        font-family: "Lato Regular";
        display: block;
        margin-left: 4px;
        clear: both;
        position: absolute;
        top: 0;
    }
    
}

.date-picker-popover {
    .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
        background: none !important;
        background-color: none !important;
    }
}