.compareto-date-picker {
  .datepicker-input input {
    padding-top: 0px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    color: #afafaf;
    margin-top: 4px;
    margin-bottom: 2px;
    width: 115px;
    font-size: 13px;
  }

  .MuiButtonBase-root {
    margin-right: -20px;
    padding-left: 0px;
  }
}

.label-days {
  font-size: 13px;
  line-height: 20px;
  color: #333;
}

.tabular-data-filter {
  font-size: 13px;

  color: #333;
}

.work-space-wrapper {
  .work-space {
    &.controls {
      .merged-title{
        border-color: #8fd6f0;
        .MuiChip-label{
          color: #606060;
        }
        .MuiButtonBase-root{
          border-color: #8fd6f0;
          .MuiSvgIcon-root {
            color: #B0E80E;
          }
        }
      }
      .btn-ratepush {
        &>span {
          background-color: #80CF46;
          color: #181818;
        }
      }

      .PushRateButton {
        //margin-right: 8px;
        // position: relative;
        background-color: #5E6064;
        height: 27px;
        color: #fff;
        padding-right: 8px;

        &:before {
          display: inline-block;
          content: " ";
          width: 20px;
          height: 17px;
          background: url("../../../public/assets/img/upload-white.svg") 0 0 no-repeat;
          //   background: url("/public/assets/img/Curency.svg") 0 0 no-repeat;
          background-size: cover;
          margin: 0 5px;
        }
        &:disabled{
          opacity: 0.6;
        }
        &:hover {
          background-color: #77797D;
          // border: 1px solid #77797D;
          // color:$Greys;
        }

        .btn-ratepush {
          &>span {
            background-color: #80CF46;
            color: #181818;
          }
        }
      }

      .acceptall {
        margin-right: 8px;
        // position: relative;
        background-color: #5E6064;
        height: 27px;
        color: #fff;
        padding-right: 8px;

        &:before {
          display: inline-block;
          content: " ";
          width: 20px;
          height: 17px;
          background: url("../../../public/assets/img/checkall.svg") 0 0 no-repeat;
          //   background: url("/public/assets/img/Curency.svg") 0 0 no-repeat;
          background-size: cover;
          margin: 0 5px;
        }

        &:hover {
          background-color: #77797D;
          // border: 1px solid #77797D;
          // color:$Greys;
        }

        .btn-ratepush {
          &>span {
            background-color: #80CF46;
            color: #181818;
          }
        }
      }

      .closeall {
        margin-right: 8px;
        // position: relative;
        background-color: #5E6064;
        height: 27px;
        color: #fff;
        padding-right: 8px;

        &:before {
          display: inline-block;
          content: " ";
          width: 20px;
          height: 17px;
          background: url("../../../public/assets/img/closeall.svg") 0 0 no-repeat;
          //   background: url("/public/assets/img/Curency.svg") 0 0 no-repeat;
          background-size: cover;
          margin: 0 5px;
        }

        &:hover {
          background-color: #77797D;
          // border: 1px solid #77797D;
          // color:$Greys;
        }

        .btn-ratepush {
          &>span {
            background-color: #80CF46;
            color: #181818;
          }
        }
      }
      .control-btn{
        width: max-content;
      }
    }
    .control-scroll{
      height: 54px;
      &::-webkit-scrollbar {
         height: 4px; 
        // height: 4px;
        // position: absolute;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow:0 0 3px rgba(0, 0, 0, 0.5); 
        border-radius: 5px; 
        // width:3px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        box-shadow: none;
        border-radius: 5px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(182, 180, 180);
      }
    }
    .control-grid-item{
      margin-left: 18px;
    
      &:first-child{
        margin-left: 0;
      }
      &.table-type{
        margin-left:10px;
      }
      .control-option{
        padding-left: 0;
      }
      &.toggle-room-rype-pricing{
        .control-option{
          padding-left: 10px;
        }
      }
      &.sdly-button{
        margin-left: 0px; 
        margin-right: -12px;
        padding-left: 2px;
      }
      &.clear-comparison-btn{
        margin-top: 17px;
      }
      .MuiSelect-select{
        padding-top: 0px;
        padding-bottom: 0;
      }
      .smart-date-picker{
        margin-right: 0;
      }
    }
    .restriction-badge {
      top: 5px;
    }
  }

  .display-picker-hide {
    display: none;
  }

  // Room Type Pricing
  .rooms-category {
    color: #333;
    opacity: 1;

    .room-class-category-tab {
      font-size: 14px;
      background-color: #E7E7E7;
      padding: 10px 28px;
      margin-right: 0px;
      min-height: auto;
      text-transform: uppercase;
      opacity: 1;

      &:hover {
        color: #333;
      }
    }

    .Mui-selected {
      background-color: #5E6064;
      opacity: 1;
      color: #B0E80E;

      &:hover {
        color: #B0E80E;
      }
    }
  }

  .room-type-pricing {
    opacity: 1;
    color: #333;
    background-color: #C1C0BF;
    padding: 5px 10px;

    .MuiButtonBase-root {
      background-color: #C1C0BF;
      font-size: 12px;
      color: #333;
      min-width: auto;
      min-height: auto;
      padding: 5px 20px;
    }

    .primary-selected-tab {
      background-color: #5E6064;
      opacity: 1;
      color: #fff;
      border-radius: 4px;

    }

    .room-code-category-tab {
      background-color: #000;
      color: #B0E80E;
    }
  }

  // RTBPRICING POPUp
  .room-property {
    avtar {
      height: 30px;
    }

    .property-value {
      width: 200px;

      span {
        color: #C02E2E;
      }
    }

    .avtar-size {
      color: #2580B4;
      min-width: 70px;
      background: #E2E2E2;
      height: 32px;
      margin: 0;
      border-radius: 21px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      padding-left: 10px;
      line-height: 30px;
    }

    .delete-button {
      color: #C02E2E;
      display: inline-block;
      height: 32px;
      padding: 0px 10px;
    }
  }
}

.pickup-days {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.pricing-buttons{
  margin-top: 19px;
  top: 3px;
  right: 120px;
  position: absolute;
}