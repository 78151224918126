.flex {
  display: flex;
  flex-flow: row;
}
.flex > .col {
  width: 100%;
}

.grid-simple .row {
  position: relative;
  width: 100%;
  display: flex;
  margin: 0;
}
.grid-simple .row > .col-1 {
  width: 8.3333333333%;
}
.grid-simple .row > .col-2 {
  width: 16.6666666667%;
}
.grid-simple .row > .col-3 {
  width: 25%;
}
.grid-simple .row > .col-4 {
  width: 33.3333333333%;
}
.grid-simple .row > .col-5 {
  width: 41.6666666667%;
}
.grid-simple .row > .col-6 {
  width: 50%;
}
.grid-simple .row > .col-7 {
  width: 58.3333333333%;
}
.grid-simple .row > .col-8 {
  width: 66.6666666667%;
}
.grid-simple .row > .col-9 {
  width: 75%;
}
.grid-simple .row > .col-10 {
  width: 83.3333333333%;
}
.grid-simple .row > .col-11 {
  width: 91.6666666667%;
}
.grid-simple .row > .col-12 {
  width: 100%;
}