/* 
Styles for Datepicker
*/

/*! CSS Used from: https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/css/font-awesome.min.css?t=1643120885454 */
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transform:translate(0, 0);}
.fa-chevron-left:before{content:"\f053";}
.fa-chevron-right:before{content:"\f054";}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/normalize.css?t=1643120885454 */
button{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
button::-moz-focus-inner{border:0;padding:0;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/bootstrap.min.css?t=1643120885454 */
button{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
button::-moz-focus-inner{border:0;padding:0;}
@media print{
*,*:before,*:after{background:transparent!important;color:#000!important;-webkit-box-shadow:none!important;box-shadow:none!important;text-shadow:none!important;}
.label{border:1px solid #000;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
button{font-family:inherit;font-size:inherit;line-height:inherit;}
label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:bold;}
.label{display:inline;padding:.2em .6em .3em;font-size:75%;font-weight:bold;line-height:1;color:#fff;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:.25em;}
.label:empty{display:none;}
.clearfix:before,.clearfix:after{content:" ";display:table;}
.clearfix:after{clear:both;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/app.css?t=1643120885454 */
.graph-header button{padding:0;margin:0;background:transparent;border:none;outline:none;color:#afafaf;transition:color 0.5s ease;}
.graph-header button:hover{color:#616060;}
.icons-left .current-date-range label{display:block;font-weight:600;font-size:11px;margin-bottom:1px;line-height:14px;margin-top:1px;text-transform:uppercase;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/themes/default/css/style.css?t=1643120885454 */
::-webkit-scrollbar{width:8px;height:8px;}
::-webkit-scrollbar-track{background-color:transparent;}
::-webkit-scrollbar-thumb{-webkit-border-radius:10px;background:rgba(96,96,96,0.8);-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5);}
.chart-stay-date{float:left;}
.chart-stay-date .label{color:#afafaf;line-height:normal;text-align:left;text-transform:uppercase;font-size:75%;margin:0px;padding:0; padding-bottom: 5px;}
.chart-stay-date .label span:last-child{color: #333;}
.stay-date-picker{border:1px solid #E4E4E4;border-radius:6px;float:left;}
.stay-date-picker >button{
    padding:4px 4px;font-size:13px;height:28px;height:auto;float:left;
    border-radius:0px; border-color:#E4E4E4 ;
    outline: none;
}
.stay-date-picker .btn-stay-range{background:#E4E4E4;display:inline-block;color:#01A4EF; border: none; height: 26px; width: 18px;}
.stay-date-picker .btn-stay-range:last-child{padding-left: 0;}
.stay-date-picker .btn-stay-picker{padding-right:4px;min-width:210px;text-align:left; 
    border: none; height: 26px; background-color: #fff; color: #afafaf;}
.stay-date-picker .btn-stay-picker:hover,
.stay-date-picker .btn-stay-picker:focus{
    color: #616060;
}   
.stay-date-picker .current-date-range label{margin-top:5px;font-size:75%;font-weight:bold;color:#afafaf;}
.stay-date-picker .current-date-range label span:last-child{color:#333;}
/*! CSS Used fontfaces */
@font-face{font-family:'FontAwesome';src:url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.eot?v=4.3.0');src:url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.3.0') format('embedded-opentype'),url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'),url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'),url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'),url('https://app.lodgiq.com/app/v3/themes/default/css/font-awesome/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');font-weight:normal;font-style:normal;}
.chart-stay-date >label{display: block;}



/* --------------- */


/*! CSS Used from: https://app.lodgiq.com/app/v3/modules/lodgiq-calendar/style.css?t=1643120885454 */
.range-types{margin-bottom:8px;}
.btn-apply{padding-bottom:10px;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/normalize.css?t=1643120885454 */
hr{box-sizing:content-box;height:0;}
button,input{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
input{line-height:normal;}
input[type="radio"]{box-sizing:border-box;padding:0;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/bootstrap.min.css?t=1643120885454 */
hr{-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;height:0;}
button,input{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
input{line-height:normal;}
input[type="radio"]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;}
@media print{
*,*:before,*:after{background:transparent!important;color:#000!important;-webkit-box-shadow:none!important;box-shadow:none!important;text-shadow:none!important;}
h3{orphans:3;widows:3;}
h3{page-break-after:avoid;}
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
input,button{font-family:inherit;font-size:inherit;line-height:inherit;}
hr{margin-top:20px;margin-bottom:20px;border:0;border-top:1px solid #eee;}
h3{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h3{margin-top:20px;margin-bottom:10px;}
h3{font-size:24px;}
.row{margin-left:-15px;margin-right:-15px;}
.col-md-6,.col-md-8,.col-md-12{position:relative;min-height:1px;padding-left:15px;padding-right:15px;}
@media (min-width:992px){
.col-md-6,.col-md-8,.col-md-12{float:left;}
.col-md-12{width:100%;}
.col-md-8{width:66.66666667%;}
.col-md-6{width:50%;}
}
label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:bold;}
input[type="radio"]{margin:4px 0 0;margin-top:1px \9;line-height:normal;}
input[type="radio"]:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.form-control{display:block;width:100%;height:34px;padding:6px 12px;font-size:14px;line-height:1.42857143;color:#555;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:4px;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-webkit-transition:border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;-o-transition:border-color ease-in-out .15s, box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s, box-shadow ease-in-out .15s;}
.form-control:focus{border-color:#66afe9;outline:0;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);}
.form-control::-moz-placeholder{color:#999;opacity:1;}
.form-control:-ms-input-placeholder{color:#999;}
.form-control::-webkit-input-placeholder{color:#999;}
.form-control::-ms-expand{border:0;background-color:transparent;}
.form-group{margin-bottom:15px;}
.btn{display:inline-block;margin-bottom:0;font-weight:normal;text-align:center;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;white-space:nowrap;padding:6px 12px;font-size:14px;line-height:1.42857143;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.btn:focus,.btn:active:focus,.btn.active:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.btn:hover,.btn:focus{color:#333;text-decoration:none;}
.btn:active,.btn.active{outline:0;background-image:none;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);}
.btn-default{color:#333;background-color:#fff;border-color:#ccc;}
.btn-default:focus{color:#333;background-color:#e6e6e6;border-color:#8c8c8c;}
.btn-default:hover{color:#333;background-color:#e6e6e6;border-color:#adadad;}
.btn-default:active,.btn-default.active{color:#333;background-color:#e6e6e6;border-color:#adadad;}
.btn-default:active:hover,.btn-default.active:hover,.btn-default:active:focus,.btn-default.active:focus{color:#333;background-color:#d4d4d4;border-color:#8c8c8c;}
.btn-default:active,.btn-default.active{background-image:none;}
.btn-info{color:#fff;background-color:#5bc0de;border-color:#46b8da;}
.btn-info:focus{color:#fff;background-color:#31b0d5;border-color:#1b6d85;}
.btn-info:hover{color:#fff;background-color:#31b0d5;border-color:#269abc;}
.btn-info:active{color:#fff;background-color:#31b0d5;border-color:#269abc;}
.btn-info:active:hover,.btn-info:active:focus{color:#fff;background-color:#269abc;border-color:#1b6d85;}
.btn-info:active{background-image:none;}
.btn-block{display:block;width:100%;}
.btn-group{position:relative;display:inline-block;vertical-align:middle;}
.btn-group>.btn{position:relative;float:left;}
.btn-group>.btn:hover,.btn-group>.btn:focus,.btn-group>.btn:active,.btn-group>.btn.active{z-index:2;}
.btn-group .btn+.btn{margin-left:-1px;}
.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle){border-radius:0;}
.btn-group>.btn:first-child{margin-left:0;}
.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle){border-bottom-right-radius:0;border-top-right-radius:0;}
.btn-group>.btn:last-child:not(:first-child){border-bottom-left-radius:0;border-top-left-radius:0;}
.btn-group-justified{display:table;width:100%;table-layout:fixed;border-collapse:separate;}
.btn-group-justified>.btn{float:none;display:table-cell;width:1%;}
[data-toggle="buttons"]>.btn input[type="radio"]{position:absolute;clip:rect(0, 0, 0, 0);pointer-events:none;}
.popover{position:absolute;top:0;left:0;z-index:1060;display:none;max-width:276px;padding:1px;font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;font-style:normal;font-weight:normal;letter-spacing:normal;line-break:auto;line-height:1.42857143;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;word-wrap:normal;font-size:14px;background-color:#fff;-webkit-background-clip:padding-box;background-clip:padding-box;border:1px solid #ccc;border:1px solid rgba(0,0,0,0.2);border-radius:6px;-webkit-box-shadow:0 5px 10px rgba(0,0,0,0.2);box-shadow:0 5px 10px rgba(0,0,0,0.2);}
.popover-title{margin:0;padding:8px 14px;font-size:14px;background-color:#f7f7f7;border-bottom:1px solid #ebebeb;border-radius:5px 5px 0 0;}
.popover-content{padding:9px 14px;}
.popover>.arrow,.popover>.arrow:after{position:absolute;display:block;width:0;height:0;border-color:transparent;border-style:solid;}
.popover>.arrow{border-width:11px;}
.popover>.arrow:after{border-width:10px;content:"";}
.clearfix:before,.clearfix:after,.row:before,.row:after{content:" ";display:table;}
.clearfix:after,.row:after{clear:both;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/bootstrap-additions.min.css?t=1643120885454 */
.row:after,.row:before{content:" ";display:table;}
.row:after{clear:both;}
.popover.bottom-left{margin-top:10px;}
.popover.bottom-left .arrow{margin-left:-11px;border-top-width:0;border-bottom-color:#999;border-bottom-color:rgba(0,0,0,.25);top:-11px;left:10%;}
.popover.bottom-left .arrow:after{content:" ";top:1px;margin-left:-10px;border-top-width:0;border-bottom-color:#fff;}
.row:after,.row:before{content:" ";display:table;}
.row:after{clear:both;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/assets/css/angular-motion.min.css?t=1643120885454 */
.am-fade{-webkit-animation-duration:.3s;animation-duration:.3s;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-fill-mode:backwards;animation-fill-mode:backwards;opacity:1;}
/*! CSS Used from: https://app.lodgiq.com/app/v3/themes/default/css/style.css?t=1643120885454 */
::-webkit-scrollbar{width:8px;height:8px;}
::-webkit-scrollbar-track{background-color:transparent;}
::-webkit-scrollbar-thumb{-webkit-border-radius:10px;background:rgba(96,96,96,0.8);-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5);}
.popover{width:100%;}
.popover-content{padding:9px;}
.range-segments{padding:0 0 4px 0;}
.range-segments label.btn{position:relative;padding-left:25px;box-shadow:none;margin-right:20%; padding-top: 3px; padding-bottom: 3px;}
.range-segments label.btn.active{color:#01A4EF;}
.range-segments label.btn:before{content:" ";border:1px solid #01A4EF;border-radius:20px;-webkit-border-radius:20px;-moz-border-radius:20px;position:absolute;left:0px;top:5px;width:16px;height:16px;}
.range-segments label.btn.active:after{content:" ";border:1px transparent;border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;position:absolute;left:4px;top:9px;width:8px;height:8px;background:#01A4EF;}
.range-selector input[type="text"],.asof-selector input[type="text"]{width:100%;padding:10px 5px;}
hr{margin:0 -10px 10px -10px;color:#333;}

/* ---React calendar CSS---- */
.react-calendar__navigation{
    margin: 0 auto;
    text-align: center;
    width: 96%;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #ccc;
}
.react-calendar__navigation button{
    border: none;
    line-height: 24px;
    background: #efefef;
}
.react-calendar__viewContainer{
    width: 96%;
    margin: 0 auto;
    margin-bottom: 15px;
}
.react-calendar__month-view__weekdays{
    text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday{
    text-align: center;
    padding: 3px 0;
    color: #afafaf;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
}
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}
.react-calendar__tile{
    border: none; background: none;
    line-height: 29px;
    border-radius: 5px;
}
.react-calendar__tile:hover{
    background: #01A4EF;
    color: #fff;
}

.react-calendar__tile.react-calendar__tile--now{
    background: #01A4EF;
    color: #fff;
    border-radius: 5px;
}
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth{
    color: #ccc;
}
.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth:hover{
    color: #fff;
}



