@font-face {
    font-family: 'Lato-Light';
    font-style: normal;
    src: url("./fonts/Lato/light/Lato-Light.woff") format("woff");
}
@font-face {
    font-family: 'Lato-Hairline';
    font-style: normal;
    src: url("./fonts/Lato/hairline/Lato-Hairline.woff") format("woff");
}

.pop-up-header{background:#434343;
    background:-moz-linear-gradient(top, #434343 0%, #333333 100%);
    background:-webkit-linear-gradient(top, #434343 0%, #333333 100%);
    background:linear-gradient(to bottom, #434343 0%, #333333 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#434343', endColorstr='#333333',GradientType=0 );
    padding:8px 11px;border-radius:4px 4px 0 0px;}
.pop-up-header .right{float: right;}
.pop-up-header .close-icon{background-color:#333333;color:#fff;}
.tile-title{display:inline-block;text-align:center;color:#8ed7ee;margin-left:5px;}
.tile-title .tile-name{color:#fff;text-transform:uppercase;font-size:15px;margin-top:1px;display:inline-block;}

.price-calendar-control{
    background: #606060;
    text-transform: uppercase;
    color: #FFF;
}
.date-number{
    position: absolute;
    left: 15px;
    top: 8px;
    font-size: 24px;
    color: #ADAFB2;
}
.days-update-count{width: 20px; height:20px; border-radius:50%; position:static; color: #fff;}
.days-update-count span{position: static; display: block; text-align: center; padding: 0; line-height: 20px; font-weight: 400; transform: none; -webkit-transform: none;}
.days-update-label{line-height: 36px; text-transform: uppercase; font-size: 13px;}

/* ---STYLE FOR DATE BOX--- */
.date-box{cursor: pointer; position: relative;}
.date-overlay{
    content: " "; width: 100%; height: 100%; border-radius: 5px;
    background: url('./assets/svg/icon-zoom.svg') center center no-repeat rgba(0, 0, 0, 0); 
     background-size: 0%;
    transition: background 0.5s, color 0.5s, background-size 0.5s;
    -webkit-transition: background 0.5s, color 0.5s, background-size 0.5s;
    color: white; position: absolute;
    z-index: 100; left: 0; top: 0;
}
.date-box:hover .date-overlay{
    background: url('./assets/svg/icon-zoom.svg') center center no-repeat rgba(0, 0, 0, 0.4);
    background-size: 36px auto;
    transition: background 0.5s, color 0.5s, background-size 0.5s;
    -webkit-transition: background 0.5s, color 0.5s, background-size 0.5s;
}
.date-occ{
    font-size: 10px;
    position: absolute;
    text-transform: none;
    left: 20%;
    right: 20%;
    top: -8px;
    background: #c5eaf6;
    border-radius: 10px;
    padding: 3px;
    color: #1a8fff;
    z-index: 10;
}
.btn-plus{
    height: 30px;
    width: 30px;
    display: inline-block;
    border: 0px solid #a5e0f1;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    border-radius: 50% !important;
    color: #9ed00d;
    text-align: center;
    position: absolute; z-index: 300; right: -8px; top: -8px;
    background: #c5eaf6;
    -webkit-transition: background 0.8s;
    transition: background 0.8s;
    padding: 0;
}
.btn-plus::before{
    content: " ";
    display: block;
    background: url("assets/svg/Icon_AddRate_CalDay.svg") center center no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    ;
}
.btn-plus.active::before,
.btn-plus:focus::before{
    background: url("assets/svg/Icon-checkmark.svg") center center no-repeat;
    background-size: 100% 100%;
}
.date-footer{
    position: absolute; bottom: 5px; text-align: center; width: 100%;
}
.date-curr-rate{
    color: #606060;
    font-size: 18px;
    line-height: normal;
    text-decoration: line-through;
}
.date-rec-rate{
    font-size: 30px;
    color: #ADAFB2;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.date-rec-rate.positive{
    color: #9ed00d;
}
.date-rec-rate.negative{
    color: #e60f0e;
}
.grey-section .date-rec-rate,.grey-section .date-rec-rate.date-rec-rate.positive,.grey-section .date-rec-rate.date-rec-rate.negative {
    color: #ADAFB2;
}
/* ---DAY DETAIL POPUP--- */
.day-title{
    font-family: 'Lato-Hairline', sans-serif;
    font-size: 22px;
    text-align: center;
    margin-bottom: 40px; margin-top: 0;
}
.rate-title{
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    color: #333;
}
.rate{
    color: #01a4ef;
    font-family: "Lato-Light", sans-serif;
    text-align: left;
    font-size: 26px;
}
.compset-asof{
    font-size: 12px;
}
.day-detail-side-btn{
    background-color: #606060;
    color: #9ed00d;
}
.day-detail-side-btn:hover{
    background: #606060;
    color: #9ed00d;
}

.competitor-current-rate{
    .MuiPaper-elevation1 {
        border-radius: 0;
        /* border-bottom-right-radius: 5px; */
    }
}
 .rate-box{
    border-radius: 5px;
}
.competitor-current-rate{
   border-radius: 0px;
   border-bottom-right-radius: 5px;
}
@media only screen and (min-height:900px){
    .competitor-current-rate{
        height: 100%;
    }
}

.pricingCalenderPopup{
    width: 94vw;
}
@media only screen and (min-width:1900px){  
    .pricingCalenderPopup{
        width: 60vw;
    }
    .date-number {
        font-size: 20px;
    }
    .date-rec-rate{
        font-size: 24px;
    }
    .date-curr-rate{
        font-size: 16px;
        margin-bottom: 3px;
    }
    .pricingDetailsPopup{
        width: 40vw;
        height: 44vh;
    }
    
}
@media only screen and (min-width:2500px){
    .pricingCalenderPopup{
        width: 50vw;
    }
    .pricingDetailsPopup {
        width: 35vw;
        height: 36vh;
    }
    .btn-plus {
        width: 25px;
        height: 25px;
    }
}

